//TODO: decide whether to use this style of initialization tracking, or the
//way outlined in the queue controllers & jobs.tracker.service.js
class SearchQuery {
  constructor() {

  }

  getQuery(qObj) {
    let queryBody;
    if (typeof qObj === 'string') {
      queryBody = JSON.parse(qObj);
    } else {
      queryBody = qObj;
    }

    if (!queryBody.query) {
      return '';
    }

    let q;

    if (queryBody.query.bool) {
      q = queryBody.query.bool.must || queryBody.query.bool.filter || queryBody.query.bool.must_not;
    } else {
      // legacy
      q = queryBody.query.query_string.query;
    }

    if (Array.isArray(q)) {
      let qStr = '';

      for (const queryObj of q) {
        if (queryObj.query_string) {
          qStr = queryObj.query_string.query || queryObj.query_string;
          break;
        }

        if (queryObj.match_all) {
          qStr = '*';
          break;
        }
      }

      return qStr;
    }

    if (!q) {
      console.warn('No query found in query object', qObj, queryBody);
      return '';
    }

    if (q.query_string) {
      return q.query_string.query || q.query_string;
    }

    if (q.match_all) {
      return '*';
    }

    // Unknown
    return '';
  }
}

angular.module('sq.jobs.results.search.query.service', [])
  .service('searchQuery', SearchQuery);